<template>
    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true" :model="search">
                <el-form-item prop="title">
                    <el-input clearable @keydown.enter.native="getList" v-model="search.title" placeholder="请输入关键字" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getList(data.size)">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <!-- <el-button class="block" @click="del">批量删除</el-button> -->


                </el-form-item>
            </el-form>
        </div>

        <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border>

            <el-table-column show-overflow-tooltip prop="id" label="ID" align="left" />
            <el-table-column show-overflow-tooltip prop="user" label="操作用户" align="left" />
            <el-table-column show-overflow-tooltip prop="request" label="请求方式" align="left" />
            <el-table-column show-overflow-tooltip prop="title" label="日志标题" align="left" />
            <el-table-column show-overflow-tooltip prop="model" label="模型" align="left" />
            <el-table-column show-overflow-tooltip prop="methods" label="操作方法" align="left" />
            <el-table-column show-overflow-tooltip prop="parameter" label="请求参数" align="left" />
            <el-table-column show-overflow-tooltip prop="url" label="url地址" align="left" />
            <el-table-column show-overflow-tooltip prop="ip" label="ip地址" align="left" />
            <el-table-column show-overflow-tooltip prop="time" label="创建时间" align="left" />

        </Fanfu-Table>

    </div>
</template>

<script>
    import resizeDetector from 'element-resize-detector'


    const list = [{
            id: "001",
            user: "管理员",
            request: "post",
            title: "菜单管理",
            model: "admin",
            methods: "/menu/index",
            parameter: '{"page":"1","limit":"20","a ...',
            url: "http://admin.jiangxuanapp.com/menu/index",
            ip: "125.36.77.222",
            time: "2022-7-8 14:03:55"
        },

    ]
    export default {

        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },
                loading: false,
                select: [],
                data: {
                    list,
                    page: 1,
                    size: 15,
                    total: list.length
                }
            }
        },
        created() {
            this.getList()
        },
        methods: {
            getList(size) {
                if (size) {
                    this.data.page = 1
                    this.data.size = size
                }
                this.loading = true
                this.select = []
                this.$nextTick(() => {
                    const start = this.data.size * (this.data.page - 1)
                    const end = start + this.data.size
                    this.data.total = list.length
                    const data = []
                    for (let i = start; i < end; i++) {
                        if (list[i]) {
                            data.push(list[i])
                        }
                    }
                    this.data.list = data
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)

                    setTimeout(() => {
                        this.modifyLayout();
                    }, 1100)


                })
            },
            view(row) {
                console.log(row)
            },
            edit(row) {
                console.log(row)
            },
            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                            }, 3000);
                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    });
                });
            },




            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    var a = 141 + 60 - 35 + height - 30;
                    if ($(".level_indexI .FanfuTableBox")[0]) {

                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');

                    }


                    var b = 141 + 60 + 33 + height - 30;
                    if ($(".level_indexI .el-table")[0]) {

                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');

                    }


                });

            }

        },

        mounted() {
            this.modifyLayout();
        },
        created() {
            this.modifyLayout();
        }

    }
</script>
<style>

</style>